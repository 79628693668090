<template>
  <div class="base-list-page">
    <el-form
      :model="dataForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item prop="companyId" class="typeInput">
          <el-select
            v-model="dataForm.companyId"
            placeholder="公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="orgId" class="typeInput">
          <el-select
            v-model="dataForm.orgId"
            placeholder="供应商"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="periodId" class="typeInput">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="week" class="typeInput">
          <el-select
            v-model="dataForm.week"
            placeholder="星期"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.week"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="state">
          <el-select
            v-model="dataForm.state"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgcompany:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgcompany:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        label="供应商名称"
      >
      </el-table-column>
      <el-table-column
        prop="periodWithTimeName"
        header-align="center"
        align="center"
        label="时段"
      >
      </el-table-column>
      <el-table-column
        prop="weeks"
        header-align="center"
        align="center"
        label="星期"
      >
      </el-table-column>

      <el-table-column
        prop="isUse"
        header-align="center"
        align="center"
        label="是否有效"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isUse" type="success" size="mini">是</el-tag>
          <el-tag v-else-if="scope.row.isUse" type="warning" size="mini"
            >否</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="startDate"
        header-align="center"
        align="center"
        label="生效时间"
      >
      </el-table-column>

      <el-table-column
        prop="endDate"
        header-align="center"
        align="center"
        label="终止日期"
      >
      </el-table-column>

      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        label="创建日期"
      >
      </el-table-column>

      <el-table-column
        prop="createdBy"
        header-align="center"
        align="center"
        label="创建者"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('tc:orgcompany:info')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id, true)"
            >查看</el-button
          >
          <el-button
            v-if="isAuth('tc:orgcompany:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="isAuth('tc:orgcompany:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import AddOrUpdate from './orgcompany-add-or-update';

export default {
  mixins: [listMixin, normal],
  data() {
    return {
      dataForm: {
        companyId: '',
        orgId: '',
        periodId: '',
        week: '',
        isUse: '',
        state: [0, 1],
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      companyList: [],
      orgList: [],
      periodList: [],
      stateList: [
        { id: 0, state: '待生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已终止' },
      ],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getCompanyList();
    this.getOrgList();
    this.getPeriodList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$api({
        url: '/tc/orgcompany/list',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.dataForm,
          state: this.dataForm.state.map((i) => i).join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.totalPage = data.page.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些公司进行删除操作?`,
        url: '/tc/orgcompany/delete',
        data: ids,
        after: () => {
          this.getDataList();
        },
      });
    },
    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
  },
};
</script>
