<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select v-model="dataForm.orgId" :disabled="disabled">
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="公司" prop="companyId">
          <el-select v-model="dataForm.companyId" :disabled="disabled">
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="时段" prop="periodId">
          <el-select v-model="dataForm.periodId" :disabled="disabled">
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="星期" prop="weeks" class="fullList">
          <el-checkbox-group v-model="dataForm.weeks" size="medium">
            <el-checkbox-button
              v-for="(item, index) in weeksList"
              :key="index"
              :label="item.id"
              >{{ item.week }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="生效日期" prop="startDate">
          <el-date-picker
            v-model="dataForm.startDate"
            :disabled="disabled"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="终止日期" prop="endDate">
          <el-date-picker
            v-model="dataForm.endDate"
            :disabled="disabled"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        weeks: [1, 2, 3, 4, 5, 6, 7],
        isUnified: true,
        startDate: '',
        endDate: '',
      },
      orgList: [],
      companyList: [],
      periodList: [],
      weeksList: [
        { id: 1, week: '一' },
        { id: 2, week: '二' },
        { id: 3, week: '三' },
        { id: 4, week: '四' },
        { id: 5, week: '五' },
        { id: 6, week: '六' },
        { id: 7, week: '日' },
      ],
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
        periodId: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
        weeks: [{ required: true, message: '星期不能为空', trigger: 'blur' }],
        startDate: [
          { required: true, message: '生效日期不能为空', trigger: 'blur' },
        ],
        endDate: [
          { required: true, message: '终止日期不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.getOrgList();
    this.getCompanyList();
    this.getPeriodList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgcompany/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.orgcompany };
              this.dataForm.weeks = data.orgcompany.weeks
                ? data.orgcompany.weeks.split(',').map((item) => parseInt(item))
                : [];
            }
          });
        }
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/all`,
        method: 'get',
        params: { type: 0, isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },

    getCompanyList() {
      this.$http({
        url: `/tc/company/combo`,
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },

    getPeriodList() {
      this.$http({
        url: `/cc/global-period/all`,
        method: 'get',
        params: { isUse: 1 },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgcompany/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
              weeks: this.dataForm.weeks.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.status === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
